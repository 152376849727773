<template>
  <v-container>
    <!-- page header -->
    <v-row>
      <v-col cols="12">
        <h1 class="headline font-weight-">All AV Sheets</h1>
      </v-col>
    </v-row>
    <!-- page header -->

    <!-- data table -->
    <v-row
      ><v-col cols="12">
        <v-data-table
          :headers="headers"
          :items="avSheets"
          :items-per-page="-1"
          class="elevation-1"
        >
          <template v-slot:item="data">
            <template>
              <tr
                @click="$router.push(`av-sheets/${data.item.id}`)"
                style="cursor: pointer"
                :class="
                  data.item.adminHasReplied
                    ? 'green lighten-4'
                    : 'red lighten-4'
                "
              >
                <td>
                  {{ data.item.name }}
                </td>
                <td>
                  {{ data.item.email }}
                </td>
                <td>
                  {{ data.item.destination }}
                </td>
                <td>
                  {{ formatDate(data.item.dateCreated) }}
                </td>
                <td>
                  {{ data.item.adminHasReplied }}
                </td>
              </tr>
            </template>
          </template>
        </v-data-table>
      </v-col></v-row
    >
    <!-- data table -->
  </v-container>
</template>

<script>
import firebase from "@/plugins/firebase";
import formatDate from "@/mixins/formatDate";
export default {
  mixins: [formatDate],
  data: () => ({
    avSheets: [],
    headers: [
      { text: "Name", value: "resortName" },
      { text: "Email", value: "email" },
      { text: "Destination", value: "destination" },
      { text: "Date Created", value: "dateCreated" },
      { text: "Admin Replied", value: "adminHasReplied" },
    ],
  }),
  async mounted() {
    // loading avSheets and adding data to each object
    await this.$store.dispatch("setLoading", true);
    let avSheetsRef = firebase.firestore().collection("avSheets");
    let avSheetDocs = await avSheetsRef.get();
    avSheetDocs = avSheetDocs.docs.map((i) => i.data());

    for (let index = 0; index < avSheetDocs.length; index++) {
      // add userAccount data
      let userAccountDocRef = firebase
        .firestore()
        .collection("userAccounts")
        .doc(avSheetDocs[index].userID);
      let userAccount = await userAccountDocRef.get();
      let userAccountData = userAccount.data();
      avSheetDocs[index].name =
        userAccountData.firstName + " " + userAccountData.lastName;
      avSheetDocs[index].email = userAccountData.email;
      avSheetDocs[index].destination = userAccountData.destination;
      // add userAccount data
      // add package data
      let eventReportsRef = firebase
        .firestore()
        .collection("eventReports")
        .where(
          "winnerPackageIDs",
          "array-contains",
          avSheetDocs[index].packageID
        );
      let eventReports = await eventReportsRef.get();
      eventReports = eventReports.docs.map((i) => i.data());
      eventReports = eventReports[0];
      let specificWinner = eventReports.winners.filter(
        (i) => i.packageID == avSheetDocs[index].packageID
      )[0];
      avSheetDocs[index].destination = specificWinner.destination;
      // add package data
      this.avSheets.push(avSheetDocs[index]);
    }
    await this.$store.dispatch("setLoading", false);
  },
};
</script>